/* make footer bottom eve nless content*/

/* * {
  box-sizing: border-box;
} */
html,
body {
  overflow-x: hidden;
}
body {
  background-color: rgb(255, 254, 252);
}

/* table, */
th,
td {
  /* border-left: 0.5px solid #c5c5c5; */
  /* border-right: 0.5px solid #c5c5c5; */
  border-collapse: collapse;
  padding: 10px 10px;
  white-space: nowrap;
}
th {
  border-left: 0.5px solid #c5c5c5;
  border-right: 0.5px solid #c5c5c5;
}

table {
  /* table-layout: fixed; */
  /* overflow: auto; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input {
  /* border: none; */
  border-bottom: 0.5px solid #c5c5c5;
  background-color: transparent;
  width: 100%;
  /* white-space: nowrap; */
  /* max-width: 100%; */

  outline: none;
}

input[type="number"] {
  text-align: right;
}

th input {
  color: white;
}

th input::placeholder {
  color: white;
  opacity: 0.6;
}

th {
  color: white;
  min-width: max-content;
  /* min-width: min-content; */
}

td {
  /* width: 25%; */
  min-width: max-content;
}

td input::placeholder {
  /* this does something */
  min-width: max-content;
}

tfoot td {
  color: white;
}

/* md */
/* @media screen (max-width: 768px) {
} */
